<template>
  <div>
    <v-fade-transition>


      <v-row>

        <v-col
            v-for="registro in inscripciones_disponibles"
            :key="registro.nombre"
            xs="12"
            sm="6"
            md="6"
            lg="3"
            xl="3"
        >
          <router-link :to="{ name: 'TipoInscripcion', params: {tipoInscripcion: registro.tipo}}">

            <div class="d-flex flex-column px-4 px-md-0">

              <v-img
                  :src="require(`@/assets/img/iconos-inscripciones/${registro.img}.png`)"
                  eager
                  class="oscurecer"
              />

              <p class="pt-4 mb-0 text-body-2 text-right descripcion-registros">
                {{ registro.descripcion }}
              </p>
            </div>

          </router-link>

        </v-col>


      </v-row>

    </v-fade-transition>
  </div>
</template>

<script>
export default {
  name: "InscripcionesViewMenu",
  data() {
    return {
      inscripciones_disponibles: [
        {
          nombre: "Inscripción de propiedad",
          img: "propiedad",
          tipo: "inscripcion-propiedad",
          descripcion: "Realice inscripciones de propiedad, prohibiciones o hipotecas desde la web"
        }, {
          nombre: "Constitución de Sociedad",
          img: "comercio",
          tipo: "constitucion-de-sociedad",
          descripcion: "Realice inscripciones de comercio..."
        },

      ]
    }
  }
}
</script>

<style scoped>
.descripcion-registros {
  color: #088397 !important;
}

a {
  text-decoration-line: none !important;
}

.oscurecer:hover {
  transition-duration: 500ms;
  transition-property: filter;
  filter: brightness(0.8)
}
</style>
